.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  background-color: rgb(255, 255, 255);
}

@media(max-width: 450px) {
  .container {
    background-color: rgb(255, 255, 255);
    padding: 5px !important;
    overflow-x: hidden;
  }
}


.signin{
  padding-top: 5px;
}
